import { useContext } from 'react';
import { GoogleMap } from '../../components/googleMap';
import { IzoraContext } from '../../App';
import { data } from './data';
import './Home.scss';

export const Home = () => {
  const { language } = useContext(IzoraContext);
  const { 
    titlePartOne, 
    titlePartTwo, 
    titlePartThree, 
    titlePartFour, 
    emphasis, 
    contactsTitle 
  } = data[language];

  return (
    <div className="home">
      <div className='main-title-wrap'>
        <p className="main-title">
          {titlePartOne}<br/>
          <span>{emphasis}</span> 
          {titlePartTwo}<br/>
          {titlePartThree}<br/>
          {titlePartFour}<br/>
        </p>
      </div>
      <div className='home-banner'></div>
      <div className='contacts'>
        <h3 className='title'>{contactsTitle}</h3>
        <p>Westerplatte 51, 58-100 Świdnica</p>
        <p>IZORA.CONSULTING@OP.PL</p>
        <p>+48 698 007 117</p>
        <div className='social-network-icons'>
          <a className="facebook-icon" href="https://www.facebook.com/people/IZORA-Consulting/100077743830746/"><img src="/images/facebook.svg"/></a>
          <a href="https://www.instagram.com/izoraconsulting/"><img src="/images/instagram.svg"/></a>
        </div>
        <GoogleMap />
    </div>
    </div>
  );
};
