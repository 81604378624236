import './article.scss';

const formatString = (str, emphasis) => {
  let mainStr = str;
  const correctEmphasis = emphasis.filter(item => str.includes(item));
  const accum = correctEmphasis.reduce((acc, item, idx) => {
    const strInArray = mainStr.split(item);
    acc.push(strInArray[0]);
    mainStr = strInArray[1];
    correctEmphasis.length - 1 === idx && acc.push(mainStr);
    return  acc;
  }, [])

  return <>
    {accum.map((item, idx) => (
      <>
        {item}<span className='article-description-bold'>{correctEmphasis[idx]}</span>
      </>
    ))}
  </>;
};

export const Article = ({articleTitle, articleDescription, articlePoints, articleTitleEmphasis, language}) => {
  const emphasis = {
    UKR: ["UKR", "а також:", "Децизія", "01-07-2022", "2-3 місяці", "карти побуту", "3-ьох місяців"],
    PL: ["UKR", "do :", "Decyzja", "01-07-2022", "2-3 miesiące", "3 miesięcy"],
    EN: [],
  };
  
  return (
    <div className='article-wrap'>
        <h3 className={`article-title ${language !== 'UKR' ? 'article-title-bold' : ''}`}>{articleTitle}<span>{articleTitleEmphasis}</span></h3>
        <p className='article-description'>{formatString(articleDescription, emphasis[language])}</p>
        {articlePoints.length ? (
            <ul className='article-points'>
                {articlePoints.map((article, idx) => <li key={idx}>{article}</li>)}
            </ul>
        ) : null}
    </div>
  );
};