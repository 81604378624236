import { useState, createContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/home';
import { About } from './pages/about';
import { Contacts } from './pages/contacts';
import { ForForeigners } from './pages/forForeigners';
import { ForBusinessPartners } from './pages/forBusinessPartners';
import { useLocation } from 'react-router-dom';
import { Layout } from './layout';
import './App.scss';

export const IzoraContext = createContext();

const App = () => {
  let location = useLocation();
  const [language, setLanguage] = useState('PL');
  const [isModalOpen, setModalAsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <div className='app'>
      <IzoraContext.Provider value={{language, setLanguage, isModalOpen, setModalAsOpen}}>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path='/for-foreigners' element={<ForForeigners />} />
            <Route path='/for-business-partners' element={<ForBusinessPartners />} />
            <Route path='/about' element={<About />} />
            <Route path='/contacts' element={<Contacts />} />
            <Route path='*' element={<Home />} />
          </Route>
        </Routes>
      </IzoraContext.Provider>
    </div>
)};

export default App;
