import { useEffect, useState } from 'react';
import './googleMap.scss';

export const GoogleMap = ({isContactsPage = false}) => {
  const [frameWidth, setFrameWidth] = useState(400);
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if( windowWidth > 250) {
      const windowWidthWithSize = 
        isContactsPage && windowWidth >=700 ? 
        windowWidth - 100 : windowWidth;

      setFrameWidth(windowWidthWithSize);
    }
  }, [frameWidth]);

  return (
    <div className={`google-maps ${isContactsPage ? 'contacts-page' : null}`}>
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d297.2827050246502!2d16.49788914844635!3d50.8370893023629!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e4dbd449f1595%3A0xa208ed61adf26d78!2zV2VzdGVycGxhdHRlIDUxLCA1OC0xMDAgxZp3aWRuaWNhLCDQn9C-0LvRjNGI0LA!5e0!3m2!1sru!2sua!4v1687206914447!5m2!1sru!2sua" 
        width={frameWidth}
        height="300" 
        style={{'border':'0'}} 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  );
};
