import { useEffect, useState, useContext } from 'react';
import { data } from './data';
import { IzoraContext } from '../../App';
import './ForBusinessPartners.scss';

const generateDescription = (title, description) => (
  <>
    <h1 className='for-business-partners-title'>{title}</h1>
    <ul className='for-business-partners-description'>
      {description.map((item, idx) => (<li key={idx}>{item}</li>))}
    </ul>
  </>
);

export const ForBusinessPartners = () => {
  const { language } = useContext(IzoraContext);
  const { 
    title, 
    description, 
    advantagesTitlePartOne, 
    advantagesTitlePartTwo, 
    emphasis, 
    advantagesDescription 
  } = data[language];

  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    const windowWidth = window.innerWidth;
    if( windowWidth >= 600) {
      setIsMobile(false);
    }
  }, [isMobile]);

  return (
    <div className='for-business-partners-wrap'>
      {isMobile ? 
        (<div className='for-business-partners-banner'>
          <img src='/images/clients-background.webp'/>
        </div>
        ) : (
        <div className='for-business-partners-big-banner'>
          <div className='banner-image'></div>
          <div className='banner-description'>{generateDescription(title, description)}</div>
        </div>
        )
      }
      <div className='for-business-partners-data'>
        {isMobile ? generateDescription(title, description) : null}
        <div className='for-business-partners-advantages'>
          <h1 className='advantages-title'>
            {advantagesTitlePartOne}
            <br/>
            <span>{emphasis}</span>
            {advantagesTitlePartTwo}
          </h1>
          <div className='advantages-description'>
            {advantagesDescription.map((item, idx) => (
              <div className='advantage-item' key={idx}>
                <img src='/images/paper-clip.svg'/>
                <p className='description'>{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
