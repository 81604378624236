export const data = {
    UKR: { 
        titlePartOne: "ВАС ВІТАЄ", 
        titlePartTwo: " ЛІДЕР У СФЕРІ", 
        titlePartThree: "ЛЕГАЛІЗАЦІЇ", 
        titlePartFour: "ІНОЗЕМЦІВ", 
        contactsTitle: "КОНТАКТИ",
        emphasis: "IZORA CONSULTING - "
    },
    PL: {
        titlePartOne: "WITAMY", 
        titlePartTwo: " LIDER W DZIEDZINIE", 
        titlePartThree: "LEGALIZACJI", 
        titlePartFour: "CUDZOZIEMCÓW", 
        contactsTitle: "KONTAKTY",
        emphasis: "IZORA CONSULTING - "
    },
    EN: {
        titlePartOne: "WELCOME TO YOU", 
        titlePartTwo: " A LEADER IN THE FIELD", 
        titlePartThree: "OF LEGALIZATION OF", 
        titlePartFour: "FOREIGNERS", 
        contactsTitle: "CONTACTS",
        emphasis: "IZORA CONSULTING IS"
    },
};