export const data = {
    UKR: { 
        title: "ЗАБЕЗПЕЧИМО",
        description: [
            "Якісний та кількісний підбір персоналу, кадрове консультування та профорієнтацію;",
            "Адаптацію моделі співпраці та гнучкі рішення індивідуально до Ваших потреб;",
            "Комплекс послуг щодо продовження легального перебування Ваших співробітників без необхідності виїзду за межі країни;",
            "Ведення справ Ваших працівників з моменту подачі заяви і до моменту винесення рішення адміністративним органом.",
        ],
        advantagesTitlePartOne: "З НАМИ ",
        advantagesTitlePartTwo: "ОТРИМАЄТЕ",
        emphasis: "ВИ ",
        advantagesDescription: [
            "Легальне перебування Ваших працівників без необхідності відкриття окремого відділу легалізації.",
            "Перевірка законного перебування працівників перед прийомом на роботу.",
            "Отримання посвідки на проживання та роботу, підготовка та оформлення пакету документів.",
            "Отримання або зміна дозволу на роботу.",
            "Співпраця зі строгим дотриманням конфіденційності",
            "Проведення пошуку та попереднього відбору осіб, які відповідають Вашим критеріям."
        ],
    },
    PL: {
        title: "ZAPEWNIAMY",
        description: [
            "Usługi najwyższej jakości związane z przedłużaniem legalnego pobytu Państwa pracowników bez konieczności opuszczania kraju. Głównym aspektem naszych działań są zezwolenia na pobyt czasowy lub stały (karty pobytu).",
            "Zajmujemy się sprawą od momentu składania przedmiotowego wniosku i do wydania decyzji przez organ prowadzący postępowanie administracyjne.",
            "Świadczymy usługi  związane z rekrutacją pracowników dla naszych klientów, doradztwa personalnego oraz poradnictwa zawodowego.",
            "Oferujemy elastyczne rozwiązania i dopasowujemy model współpracy do potrzeb konkretnego Klienta.",
        ],
        advantagesTitlePartOne: "Z NAMI",
        advantagesTitlePartTwo: " ",
        emphasis: "UZYSKUJESZ",
        advantagesDescription: [
            "Legalny pobyt cudzoziemców bez konieczności utrzymania dodatkowej kadry pracowniczej w dziale HR.",
            "Weryfikacja legalności pobytu kandydatów do pracy spoza UE przed zatrudnieniem.",
            "Uzyskanie zezwolenia na pobyt i pracę, przygotowanie i kompletacja pakietu dokumentów.",
            "Otrzymanie lub zmiana zezwolenia na pracę dla cudzoziemca.",
            "Współpraca z zachowaniem pełnej poufności",
            "Przeprowadzenie wyszukiwania i wstępnej selekcji osób spełniających Twoje kryteria."
        ],
    },
    EN: {
        title: "WE OFFER",
        description: [
            "Items will be added soon...",
        ],
        advantagesTitlePartOne: "WITH US ",
        advantagesTitlePartTwo: "YOU WILL RECEIVE",
        emphasis: "YOU ",
        advantagesDescription: [
        ],
    },
};