import { useContext } from 'react';
import { IzoraContext } from '../../App';
import { data } from './data';
import './recordModal.scss';

export const RecordModal = () => {
  const { language, isModalOpen, setModalAsOpen } = useContext(IzoraContext);
  const { description, button } = data[language];

  const toggleModal = () => setModalAsOpen(!isModalOpen);

  return (
    <>
      <div className='record-modal-wrap'>
          <div className='record-modal-close-icon' onClick={toggleModal}></div>
          <div className='record-modal-content'>
            <p className='record-modal-description'>{description}</p>
            <div type="button" className='record-modal-button' onClick={toggleModal}>{button}</div>
          </div>
      </div>
      {isModalOpen && <div className='modal-overlay'></div>}
    </>
  );
};