import { useContext } from 'react';
import { data } from './data';
import { IzoraContext } from '../../App';
import './About.scss';

export const About = () => {
  const { language } = useContext(IzoraContext);
  const { title, description } = data[language];

  return (
    <div className='about-wrap'>
      <h1 className='about-title'>{title}</h1>
      <div className='about-description'>
        {description.map((item, idx) => (
          <p className='about-description-item' key={idx}>{item}</p>
        ))}
      </div>
    </div>
  );
};
