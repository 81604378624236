export const data = {
    UKR: { 
        title: "КОНТАКТИ", 
        address: "АДРЕСА",
        tel: "ТЕЛЕФОН",
        socialNetworks: "СОЦІАЛЬНІ МЕРЕЖІ",
    },
    PL: {
        title: "KONTAKTY", 
        address: "ADRES",
        tel: "TELEFON",
        socialNetworks: "PORTALE SPOŁECZNOŚCIOWE",
    },
    EN: {
        title: "CONTACTS", 
        address: "ADDRESS",
        tel: "PHONE",
        socialNetworks: "SOCIAL NETWORKS",
    },
};