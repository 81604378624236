import { useContext } from 'react';
import { NavMenu } from '../components/navMenu';
import { Outlet } from 'react-router-dom';
import { IzoraContext } from '../App';
import { RecordModal } from '../components/recordModal';

export const Layout = () => {
  const { isModalOpen } = useContext(IzoraContext);

  return (
    <>
      <NavMenu />
      <Outlet />
      {isModalOpen && <RecordModal/>}
    </>
  );
};
