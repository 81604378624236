export const data = {
    UKR: { 
        title: "Наші послуги",
        description: [
            {
                articleTitle: "ЩО ТАКЕ СТАТУС ",
                articleTitleEmphasis: "UKR?",
                articleDescription: "Статус UKR свідчить про те, що особа, яка його має, перебуває на території Польщі на підставі спеціального закону у зв`язку з воєнними діями на території України а також:",
                articlePoints: [
                    "може легально перебувати на території Польщі;", 
                    "може користуватися медичним забезпеченням;",
                    "може користуватися соціальним забезпеченням;",
                    "має спрощений доступ до ринку праці.",
                ]
            },
            {
                articleTitle: "ЩО ТАКЕ ДЕЦИЗІЯ ",
                articleTitleEmphasis: "(DECYZJA)?",
                articleDescription: "Децизія – це документ, який видає Воєвода, віповідно до місця проживання іноземця на території Польщі, що підтверджує надання чи відмову у наданні дозволу на проживання на території Республіки Польща.На підставі виданої позитивної децизії, буде надрукована карта побуту, яка разом з дійсним закордонним паспортом дає право на перетин кордону.",
                articlePoints: []
            },
            {
                articleTitle: "СКІЛЬКИ ЧАСУ ЗАЙМАЄ ОТРИМАННЯ КАРТИ?",
                articleDescription: "Від 01-07-2022 року – на підставі працевлаштування час розгляду справи від моменту складання документів до винесення рішення по справі (видачі децизії), - займає 2-3 місяці. Після цього очікування на виготовлення карти побуту – близько 3-ьох місяців (залежить від воєвудства) Тривалість розгляду справ в інших випадках не регламентована та залежить від інспектора, який її розглядає та від складності самої справи.",
                articlePoints: []
            }
        ],
        orderTitle: 'Записатися',
        answers: "Питання та відповіді"
    },
    PL: {
        title: "Nasze usługi",
        description: [
            {
                articleTitle: "CZYM JEST STATUS UKR?",
                articleDescription: "Status UKR potwierdza , że osoba posiadająca ten status przebywa w Polsce na podstawie Ustawy z dnia 12 marca 2022 r. o pomocy obywatelom Ukrainy w związku z konfliktem zbrojnym na terytorium tego państwa oraz uprawniona do :",
                articlePoints: [
                    "legalnego pobytu na terytorium RP;", 
                    "może korzystać z pomocy medycznej;",
                    "może korzystać z pomocy socjalnej;",
                    "ma uproszczony dostęp do rynku pracy.",
                ]
            },
            {
                articleTitle: "CZYM JEST DECYZJA?",
                articleDescription: "Decyzja  jest dokumentem wydawanym przez Wojewodę właściwego do miejsca zamieszkania cudzoziemca na terytorium RP potwierdzającym udzielenie lub odmowę udzielenia  zezwolenia na pobyt na terytorium RP. Na podstawie decyzji udzielającej zezwolenie na pobyt zostanie wydrukowana karta pobytu, która wraz z ważnym dokumentem podróży jest dokumentem uprawniającym na przekraczanie granicy.",
                articlePoints: []
            },
            {
                articleTitle: "ILE CZEKAMY NA KARTĘ?",
                articleDescription: "Z dnia 01-07-2022  roku – na podstawie pracy czas oczekiwania na rozpatrzenie wniosku od momentu złożenia do momentu wydania decyzji w sprawie – 2-3 miesiące. Po wydaniu decyzji pozytywniej czas oczekiwania na wydanie karty pobytu – około 3 miesięcy (zależy od województwa) Czas rozstrzygnięcia spraw innych, niż na podstawie  pracy nie jest przewidziany i uzależnia się od Inspektora prowadzącego oraz od ich komplikacji.",
                articlePoints: []
            }
        ],
        orderTitle: 'Zarezerwuj',
        answers: "Pytania i odpowiedzi"
    },
    EN: {
        title: "Our Services",
        description: [
            {
                articleTitle: "WHAT IS A LIFE MAP?",
                articleDescription: "A beaten card is...",
                articlePoints: []
            },
            {
                articleTitle: "WHAT DOCUMENTS DO YOU NEED TO CARRY WITH YOU?",
                articleDescription: "You need to have with you...",
                articlePoints: []
            },
            {
                articleTitle: "HOW LONG DOES IT TAKE TO RECEIVE THE CARD?",
                articleDescription: "Time to get a card....",
                articlePoints: []
            }
        ],
        orderTitle: 'Book Now',
        answers: "Questions and answers"
    },
};

export const cardsData = {
    images: [
        "/images/card-image-1.webp",
        "/images/card-image-2.webp",
        "/images/card-image-3.webp",
        "/images/card-image-4.webp",
        "/images/card-image-5.webp",
        "/images/card-image-6.webp",
    ],
    UKR: [
        {
            title: "Карта поляка",
            translation: "Karta Polaka",
        },
        {
            title: "Карта тимчасового побиту  - UKR",
            translation: "Karta czasowego pobytu - UKR",
        },
        {
            title: "Карта тимчасового побиту",
            translation: "Karta czasowego pobytu",
        },
        {
            title: "Карта сталого побиту",
            translation: "Karta stałego pobytu",
        },
        {
            title: "Карта резидента ЄС",
            translation: "Karta rezydenta UE",
        },
        {
            title: "Громадянство Польщі",
            translation: "Obywatelstwo Polskie",
        },
    ],
    PL: [],
    EN: [],
};