export const data = {
    UKR: { 
        title: "ПРО КОМПАНІЮ",
        description: [
            "Основний напрямок компанії IZORA Consulting це - легалізація перебування іноземців у Польщі. Від 2015 року головним аспектом нашої діяльності є карти тимчасового та сталого побиту, карти резидента ЄС та громадянство Польщі.",
            "Супровід клієнтської справи триває з моменту подання заяви і до моменту винесення рішення органом, що здійснює адміністративну процедуру.",
            "Ми підбираємо індивідуальні рішення та адаптовуємо модель співпраці для кожного клієнта."
        ],
    },
    PL: {
        title: "O NAS",
        description: [
            "Głównym kierunkiem działalności firmy IZORA Consulting jest legalizacja pobytu cudzoziemców w Polsce. Od 2015 roku głównym aspektem naszej działalności są karty czasowego i stałego pobytu, karty rezydenta UE oraz obywatelstwo polskie.",
            "Zajmujemy się sprawą od momentu złożenia wniosku do momentu wydania decyzji przez organ prowadzący postępowanie administracyjne.",
            "Oferujemy elastyczne rozwiązania i dopasowujemy model współpracy do potrzeb konkretnego Klienta."
        ],
    },
    EN: {
        title: "ABOUT THE COMPANY",
        description: [
            "The main direction of the IZORA Consulting company is the legalization of the stay of foreigners in Poland. From 2015 the main aspect of our activity has been temporary and permanent residence cards, EU resident cards and Polish citizenship.",
            "Support of the client's case continues from the moment the application is submitted and until the moment the decision is made by the body carrying out the administrative procedure.",
            "We will select an individual solutions and adapt the cooperation model for each client."
        ],
    },
};