import { useContext } from 'react';
import { data } from './data';
import { GoogleMap } from '../../components/googleMap';
import { IzoraContext } from '../../App';
import './Contacts.scss';

export const Contacts = () => {
  const { language } = useContext(IzoraContext);
  const { title, address, tel, socialNetworks } = data[language];

  return (
    <>
      <div className='contacts-wrap'>
        <h1 className='contacts-title'>{title}</h1>
        <div className='contacts-data'>
          <div className='contacts-data-row'>
            <div className='contacts-adress-wrap'>
              <h2 className='contacts-address'>{address}</h2>
              <p className='contacts-street'>Westerplatte 51, 58-100 Świdnica</p>
            </div>
            <div className='contacts-email-wrap'>
              <h2 className='contacts-email'>Email</h2>
              <p className='contacts-email-address'>IZORA.CONSULTING@OP.PL</p>
            </div>
          </div>
          <div className='contacts-data-row'>
            <div className='contacts-tel-wrap'>
              <h2 className='contacts-tel'>{tel}</h2>
              <div className='contacts-tel-numbers'>
                <span>+48 698 007 117</span>
                <span>+48 538 960 516</span>
              </div>
            </div>
            <div className='contacts-social-networks-wrap'>
              <h2 className='contacts-social-networks'>{socialNetworks}</h2>
              <div className='contacts-social-networks-icons'>
                <a className="facebook-icon" href="https://www.facebook.com/people/IZORA-Consulting/100077743830746/"><img src="/images/facebook.svg"/></a>
                <a href="https://www.instagram.com/izoraconsulting/"><img src="/images/instagram.svg"/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoogleMap isContactsPage={true}/>
    </>
  );
};
