import { useContext } from 'react';
import { data, cardsData } from './data';
import { IzoraContext } from '../../App';
import { Card } from '../../components/card';
import { Article } from '../../components/article';
import './ForForeigners.scss';

export const ForForeigners = () => {
  const { language } = useContext(IzoraContext);
  const { images } = cardsData;
  const cards = cardsData['UKR'];
  const { title, orderTitle, answers, description } = data[language];

  return (
    <div className="for-foreigners-wrap">
      <h1 className="for-foreigners-title">{title}</h1>
      <div className='cards'>
        {images.map((img, idx) => {
          const { title, translation } = cards[idx];
          return <Card 
            img={img} 
            key={img}
            title={title} 
            translation={translation}
            orderTitle={orderTitle}
          />
        })}
      </div>
      <div className='for-foreigners-answers'>{answers}</div>
      <div className='for-foreigners-background'></div>
      <div className='for-foreigners-info'>
      <div className='for-foreigners-articles'>
        {description.map(({articleTitle, articleDescription, articlePoints, articleTitleEmphasis}, idx) => {
          return <Article 
            articleTitle={articleTitle}
            articleTitleEmphasis={articleTitleEmphasis}
            articleDescription={articleDescription}
            articlePoints={articlePoints}
            language={language}
            key={idx}
          />
        })}
      </div>
      </div>
    </div>
  );
};
