import { useContext } from 'react';
import { IzoraContext } from '../../App';
import './card.scss';

export const Card = ({img, title, translation, orderTitle}) => {
  const { isModalOpen, setModalAsOpen } = useContext(IzoraContext);
  const toggleModal = () => setModalAsOpen(!isModalOpen);

  return (
    <div className='card-wrap'>
        <img className='card-image' src={img} />
        <div className='card-description'>
            <h4 className='card-description-title'>{title}</h4>
            <p className='card-description-translation'>{translation}</p>
            <button type="button" className='card-description-order' onClick={toggleModal}>{orderTitle}</button>
        </div>
    </div>
  );
};
