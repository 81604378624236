export const data = {
    UKR: { 
        main: "Головна",
        forForeigners: "Для іноземців",
        forBusinessPartners: "Для бізнес-партнерів",
        about: "Про компанію",
        contacts: "Контакти",
    },
    PL: {
        main: "Główna",
        forForeigners: "Dla cudzoziemców",
        forBusinessPartners: "Dla partnerów biznesowych",
        about: "O Nas",
        contacts: "Kontakty",
    },
    EN: {
        main: "Main",
        forForeigners: "For foreigners",
        forBusinessPartners: "For business partners",
        about: "About the company",
        contacts: "Contacts",
    },
};