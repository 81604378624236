import { useState } from 'react';
import './languageSwitchDropDown.scss';

export const LanguageSwitchDropDown = ({language, setLanguage, dropdownClassName}) => {
  const [isDropdownOpen, toggleDropdown] = useState(false);

  const clickDropdownItem = (lang) => {
    setLanguage(lang)
    toggleDropdown((state) => !state)
  }; 

  return (
    <div className={`language-switch-dropdown ${!isDropdownOpen ? 'closed' : 'opened'} ${dropdownClassName}`}>
      {isDropdownOpen ? (
        <div className="opened-dropdown">
          <div className='dropdown-item' type="button" onClick={() => clickDropdownItem("UKR")}>
            <img className="dropdown-item-img" src="/images/UKR-flag.svg"/>
            <span className='dropdown-item-title'>Ukrainian</span>
          </div>
          <div className='dropdown-item' type="button" onClick={() => clickDropdownItem("PL")}>
            <img className="dropdown-item-img" src="/images/PL-flag.svg"/>
            <span className='dropdown-item-title'>Polish</span>
          </div>
          <div className='dropdown-item' type="button" onClick={() => clickDropdownItem("EN")}>
            <img className="dropdown-item-img" src="/images/EN-flag.svg"/>
            <span className='dropdown-item-title'>English</span>
          </div>
        </div>
      ) : (
        <div className='dropdown-button' type="button" onClick={() => toggleDropdown((state) => !state)}>
          <img className="dropdown-icon-flag" src={`/images/${language}-flag.svg`} />
          <img className="dropdown-icon" src="/images/arrow.png"/>
        </div>
      )}
    </div>
  );
};