import { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { LanguageSwitchDropDown } from '../languageSwitchDropDown';
import { IzoraContext } from '../../App';
import { data } from "./data";
import './navMenu.scss';

export const NavMenu = () => {
  const [isNavbarIconChecked, toggleNavbarIcon] = useState(false);
  const { language, setLanguage } = useContext(IzoraContext);
  const { main, forForeigners, forBusinessPartners, about, contacts } = data[language]
  const isMobile = window.innerWidth <= 600;
  const dropdownClassName = !isMobile ? 'dropdown-position-on-desktop' : '';

  const onClickNavbarIcon = () => {
    toggleNavbarIcon((prev) => !prev);
    document.getElementsByTagName("body")[0].style.overflow = isNavbarIconChecked ? "visible" : "hidden";
  };

  return (
    <header className={`${isNavbarIconChecked ? 'languageSwitchDropDown' : ''}`}>
      <div id="brand">
        {(!isNavbarIconChecked || !isMobile) && (
          <>
            <Link to="/"><img src='/images/logo.webp'/></Link>
            <div className='tel-numbers'>
              <p>+48 538 960 516</p>
              <p>+48 698 007 117</p>
            </div>
          </>
        )}
        {(isNavbarIconChecked || !isMobile) && (
          <LanguageSwitchDropDown language={language} setLanguage={setLanguage} dropdownClassName={dropdownClassName} />
        )}
      </div>
      <nav>
        <ul>
          <li><NavLink to="/">{main}</NavLink></li>
          <li><NavLink to="/for-foreigners">{forForeigners}</NavLink></li>
          <li><NavLink to="/for-business-partners">{forBusinessPartners}</NavLink></li>
          <li><NavLink to="/about">{about}</NavLink></li>
          <li><NavLink to="/contacts">{contacts}</NavLink></li>
        </ul>
      </nav>
      <div
        id="hamburger-icon" 
        className={`${isNavbarIconChecked ? 'open': null}`} 
        onClick={onClickNavbarIcon}
      >
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
        <div className="bar bar3"></div>
        <ul className={`mobile-menu ${isNavbarIconChecked ? 'mobile-menu-opened' : null}`}>
          <li><NavLink to="/">{main}</NavLink></li>
          <li><NavLink to="/for-foreigners">{forForeigners}</NavLink></li>
          <li><NavLink to="/for-business-partners">{forBusinessPartners}</NavLink></li>
          <li><NavLink to="/about">{about}</NavLink></li>
          <li><NavLink to="/contacts">{contacts}</NavLink></li>
        </ul>
      </div>
    </header>
  );
};
