export const data = {
    UKR: {
        description: "Запис на цю послугу ще закритий. Щоб дізнатися деталі, зв'яжіться з нами.",
        button: "Добре",
    },
    PL: {
        description: "Przepraszamy, ta usługa nie jest jeszcze dostępna do rezerwowania online. Skontaktuj się z nami, aby uzyskać więcej informacji.",
        button: "OK",
    },
    EN: {
        description: "Sorry, this service is not yet available for online bookings. Please contact us for more information.",
        button: "Got It",
    },
};